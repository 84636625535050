import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '../components/tabs';
// import Hero from "../components/hero"
import TwoColumn from "../components/two-column"

const bannerContent = {
  title: "Security and compliance at Billsby",
  description: [
    {
      text: "EFFECTIVE 1ST AUGUST 2019"
    }
  ],
  sectionSecurity: true,
  image: require('../images/security-banner.svg'),
  svg: true,
  imageName: 'solution-fitness',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right',
  isBanner: true
}

const tabContent = [
  {
    tabTitle: "Introduction",
    heading: "The importance of data security at Billsby",
    details: <><p>Billsby is at the heart of your business – collating all of the details of your products, plans, promotions, customers and payments in one place, and managing the sharing and routing of data between all of your tools. With crucial business information and data constantly flowing through our platform, we know you expect a highly secure solution.</p>
    <p>And because Billsby holds your customers personal data too, you owe your customers the promise that all of this data will be handled safely and securely and only shared with their consent.</p></>
  },
  {
    tabTitle: "The Billsby Promise",
    heading: "The Billsby Promise",
    details: <><p>At Billsby, we take data integrity and security extremely seriously. We acknowledge our responsibilities as both a data processor and a data controller, storing your and your customers data with the care it deserves and ensuring compliance so you can be trusted whilst using Billsby to deliver a great customer experience.</p>
    <p>Security is an essential part of our product. Every member of our team is constantly working to keep your data as secure and available as possible. All of our facilities and systems are reliable, robust and resilient and we’re always looking to make our product even better.</p>
    <p>In short, we promise to let you deliver a secure subscription billing experience by:</p>
    <ul>
      <li>Securing your customers personal and payment data in a way that’s compliant with GDPR and PCI DSS</li>
      <li>Ensuring that all internal data security measures meet the exacting standards you would expect from a Software-as-a-Service provider</li>
      <li>Following best practice standards for our physical and network security at all times</li>
    </ul></>
  },
  {
    tabTitle: "PCI DSS Compliance",
    heading: "PCI DSS Compliance",
    details: <><p>The Payment Card Industry Data Security Standard (PCI DSS) is a proprietary information security standard administered by the PCI Security Standards Council, which was founded by American Express, Discover Financial Services, JCB International, MasterCard Worldwide and Visa Inc.</p>
    <p>PCI DSS applies to all entities that store, process or transmit cardholder data (CHD) or sensitive authentication data (SAD), including merchants, processors, acquirers, issuers, and service providers. The PCI DSS is mandated by the card brands and administered by the Payment Card Industry Security Standards Council.</p>
    <p>All payment cards processed through Billsby are stored in the Billsby Vault. The Billsby Vault is powered by Spreedly, and Billsby hold no credit card data, transmit no credit card data and at no stage have access to any credit card data, other than tokenised data in the Billsby Vault powered by Spreedly.</p>
    <p>You can<a href="https://www.spreedly.com/pci" rel="noopener noreferrer" target="_blank">view Spreedly’s PCI DSS compliance here</a>.</p></>
  },
  {
    tabTitle: "EU-US Privacy Shield",
    heading: "EU-US Privacy Shield",
    details: <><p>Billsby complies with the EU-U.S. Privacy Shield and U.S.- Swiss Privacy Shield by adhering to the principles of protecting the rights of anyone in the EU whose personal data is transferred to the United States as well as bringing legal clarity for businesses relying on transatlantic data transfers.</p></>
  },
  {
    tabTitle: "GDPR",
    heading: "GDPR",
    details: <><p>The General Data Protection Regulation (GDPR) is a European privacy law which became enforceable on May 25, 2018 and is intended to harmonize data protection laws throughout the European Union (EU) by applying a single data protection law that is binding throughout each member state.</p>
    <p>At our core, Billsby are committed to protecting the personal data of our customers. We only collect and store data that is necessary to offer our service, and we do this with the consent of our customers. Our approach to privacy, security and data protection align with the goals of the General Data Protection Regulation, and tools within Billsby make it easier for you to comply with your obligations under the act.</p>
    <p>Our standard<a href="https://www.billsby.com/security/" rel="noopener noreferrer" target="_blank">Data Processing Addendum</a>is available for you to sign where required by your organisation.</p></>
  },
  {
    tabTitle: "Network security",
    heading: "Network security",
    details: <><p>TBillsby uses Google Cloud’s platform and infrastructure, and our employees do not have any physical access to our production environment. You can read more about <a href="https://cloud.google.com/security/" rel="noopener noreferrer" target="_blank">Google Cloud’s industry leading security</a> on their website.</p>

    <p>In addition to physical security, the Google Cloud platform helps protect us from traditional network security issues like:</p>

    <ul>
      <li>Distributed Denial of Service (DDoS) attacks</li>
      <li>Man in the middle attacks</li>
      <li>Port scanning</li>
      <li>Packet sniffing by other tenants</li>
    </ul>

    <p>The Billsby Vault, hosted by our partner Spreedly, uses Amazon AWS platform and infrastructure. Neither Billsby or Spreedly employees have any physical access to the production environment.</p>
    <p>You can read more about <a href="https://aws.amazon.com/security/" rel="noopener noreferrer" target="_blank">Amazon’s security practices</a>on their website.</p> </>
  },
  {
    tabTitle: "Admin operations",
    heading: "Admin operations",
    details: <><p>One of the ways we keep your account secure is by limiting who can access it. We take a stringent approach to ensuring only users with specific access need can access our production environments and databases.</p>

    <p>If you need help with your account, only you can grant access to our customer service staff, and you can revoke this access at any time.</p>

    <p>Administrative access to our systems is logged, and the reasons for access documented. Changes are not typically performed to any data in the production environment by members of our team.</p></>
  },
  {
    tabTitle: "Application security",
    heading: "Application security",
    details: <><h3>Secure access</h3>
    <p>Billsby’s application services can only be accessed by HTTPS, and we use industry standard encryption for data traversing to and from the application servers.</p>

    <h3>XSS</h3>
    <p>All user input is properly encoded when displayed to ensure that XSS vulnerabilities are mitigated.</p>

    <h3>SQL injection</h3>
    <p>We use prepared statements for database access to avoid SQL injection attacks.</p>

    <h3>Encrypted dat storage</h3>
    <p>We do not store sensitive card details on any Billsby network. The keys for third party services like payment gateways and integrations are stored in our database in encrypted form, and we encrypt data whenever possible and technically feasible.</p></>
  },
  {
    tabTitle: "Storage and redundancy",
    heading: "Storage and redundancy",
    details: <><p>We use Google Cloud SQL for our database. For each instance, data is backed up each day. To ensure redundancy, data is backed up in two regions within the same continent.</p>
    <p>In addition, our entire application is geo-replicated and load balanced across multiple data centers, so in the event of a weather event or power interruption, our services will continue to be available.</p></>
  },
  {
    tabTitle: "Monitoring",
    heading: "",
    details: "",
  },
  {
    tabTitle: "Disclosure",
    heading: "Disclosure",
    details: <><p>If you find any security issues, please email <a href="mailto:hello@billsby.com" rel="noopener noreferrer" target="_blank">hello@billsby.com</a> and we will work to resolve the problem as soon as possible.</p></>
  }
]

const Security = () => {
  return (
    <Layout>
      <SEO 
        title="Security" 
        description="Billsby is PCI-DSS complaint subscription billing software that handles recurring payments safely and securely, using the latest security techniques." 
        url="https://www.billsby.com/security"
      />
      
      <div className="security">
        {/* <Hero contentHero={bannerContent} /> */}
        <TwoColumn content={bannerContent}/>

        <div className="security-holder">
          <div className="container">
            <Tabs className="container-flex" initialValue={0}>
              <TabList>
                {
                  tabContent.map((link, i) => (
                    <Tab name={i} key={i}>
                      <div className="link">{link.tabTitle}</div>
                    </Tab>
                  ))
                }
              </TabList>

              {
                tabContent.map((content, j) => (
                  <TabPanel name="application" key={j} name={j}>
                    <div className="security-content">
                      <h2 className="section-heading">{content.heading}</h2>
                      {content.details}
                    </div>
                  </TabPanel>
                ))
              }
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Security
